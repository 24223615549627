<template>
    <pagination title="Attachments" :response="response" :entities="items" :hasEntities="items !== null" :checkSize="false" header-colour-class="bg-transparent">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Type
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Attachment</th>
        </template>
        <template v-slot:items>
            <tr v-for="item in items">
                <td class="whitespace-normal pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <div>
                        <span v-if="item.attachment">
                            Attachment
                        </span>
                        <span v-else-if="item.scheduled_file">
                            Schedule File
                        </span>

                    </div>
                </td>
                <td class="whitespace-normal pl-4 pr-3 text-sm font-medium">
                    <div>
                        <download-pdf-icon-with-link v-if="item.attachment" :url="attachmentUrl(item.attachment)"></download-pdf-icon-with-link>
                        <download-pdf-icon-with-link v-else-if="item.scheduled_file" :url="attachmentUrl(item.scheduled_file)"></download-pdf-icon-with-link>
                    </div>
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
import SelectQuoteForm from "@/v3/components/forms/SelectQuoteForm.vue";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
export default {
  name: 'OrderAttachments',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false
      }
    },
    components: {
        FormAction,
        ConfirmationAction,
        SelectQuoteForm,
        DownloadPdfIconWithLink, FlagOrderAction, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.order.actions.filter(action => action.name === 'filter').first();
        },
        items() {
            if (!this.order) {
                return null;
            }
            let items = [];
            if(this.order.properties.get('attachment')){
                items.push({'attachment': this.order.properties.get('attachment')})
            }
            if(this.order.properties.get('scheduled_file')){
                items.push({'scheduled_file': this.order.properties.get('scheduled_file')})
            }
            return items;

            return this.order.entities.filter(entity => entity.rels.contains('goods_in_note'));
        }
    },
  props: {
    order: {},
      response: {
        type: Object,
          default: null
      }
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        showCreatedDaysAgo(orderEntity){
            let createdDate = dayjs(orderEntity.entity.properties.get('created_at'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'),'seconds').humanize(true)
        },
        showUpdatedDaysAgo(orderEntity){
            let createdDate = dayjs(orderEntity.entity.properties.get('updated_at_unformatted'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'),'seconds').humanize(true)
        },
        altViewClicked(){
            this.$emit('altViewClicked');
            this.viewUser = false;
        },
        attachmentUrl(url){
            if(!url.includes('/')){
                return process.env.VUE_APP_API_URL +'/api/files/attachments/' + url;
            }
            if(url.includes('public')){
                return process.env.VUE_APP_API_URL + '/' + url.replace('public', 'storage');
            }
            return process.env.VUE_APP_API_URL +'/api/files/' + url;
        },
    }
}
</script>
