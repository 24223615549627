<template>
    <div>
        <custom-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </custom-button>
        <portal to="destination">
        <large-model-with2-buttons v-show="displayModal" :submitting="submittingModal" :submittingSecond="submittingSecond" :title="action.title" :data-cy="`action-button-${action.name}`"
                                   :confirmationString='`Are you sure you want to \"${action.title}\"`'
                                   @removeFromDisplay="displayModal = false" @firstPerform="perform()" @secondPerform="secondPerform" :firstButtonTitle="firstButtonTitle" :secondButtonTitle="getSecondButtonTitle()" :showSecondButton="showSelectCompetingQuoteButton">
            <div class="grid gap-2" :class="{'grid-cols-2': action.fields.filter(field => field.type !== 'hidden' && (field.name === 'cms_pdf_file' || field.name === 'cms_total' || field.name === 'cms_total_inc_tax' || field.name === 'is_proforma') ).first()}">
                <div class="grid auto-rows-min" :class="{'grid-cols-2': action.fields.filter(field => field.type !== 'hidden' && (field.name === 'cms_pdf_file' || field.name === 'cms_total' || field.name === 'cms_total_inc_tax' || field.name === 'is_proforma') ).first()}">
                    <collapsable-header class="col-span-2" :collapsable="false" title="Details">
                    </collapsable-header>
                    <template v-for="field in action.fields.filter(field => field.type !== 'hidden' && (field.name === 'pdf_file'|| field.name === 'supplier_pdf_file'|| field.name === 'contact_id' || field.name === 'total'|| field.name === 'currency' || field.name === 'includes_vat' || field.name === 'manufacturer_id' || field.name === 'account_used' || field.name === 'delivery_address') )">
                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            {{ field[1].title }}
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field v-if="field[0] === 'contact_id'"
                                :action="action"
                                :fieldId="field[0]"
                                :show-label="false"
                                v-model="formData[field[0]]"
                               @input="supplierChanged">
                            </property-or-field>
                            <property-or-field v-else
                                               :action="action"
                                               :fieldId="field[0]"
                                               :show-label="false"
                                               v-model="formData[field[0]]">
                            </property-or-field>
                            <div v-show="errors.has(field[0])" class="text-v3-red text-sm">{{ errors.first(field[0]) }}</div>
                        </div>
                    </template>
                </div>
                <div class="grid grid-cols-2 auto-rows-min" v-if="action.fields.filter(field => field.type !== 'hidden' && (field.name === 'cms_pdf_file' || field.name === 'cms_total' || field.name === 'cms_total_inc_tax' || field.name === 'is_proforma') ).first()">
                    <collapsable-header class="col-span-2" :collapsable="false" title="Use CMS Quote">
                    </collapsable-header>
                    <template v-for="field in action.fields.filter(field => field.type !== 'hidden' && (field.name === 'cms_pdf_file' || field.name === 'cms_total' || field.name === 'cms_total_inc_tax' || field.name === 'is_proforma') )">
                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            {{ field[1].title }}
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                :fieldId="field[0]"
                                :show-label="false"
                                v-model="formData[field[0]]">
                            </property-or-field>
                            <div v-show="errors.has(field[0])" class="text-v3-red text-sm">{{ errors.first(field[0]) }}</div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

        </large-model-with2-buttons>
        </portal>
    </div>
</template>

<script>
import Modal from '@/components/Modal';
import fileDownload from "js-file-download";
import SlideOverWith2Buttons from "@/v2/components/SlideOverWith2Buttons";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import LargeModelWith2Buttons from "@/v3/models/LargeModelWith2Buttons.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";

export default {
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            submittingSecond: false,
            showSelectCompetingQuoteButton: true
        }
    },
    components: {
        CollapsableHeader, PropertyOrField,
        LargeModelWith2Buttons,
        CustomButton,
        SlideOverWith2Buttons,
        Modal
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        secondAction: {
            type: Object,
            default: null
        },
        additionalButtonClasses:{
            type: String
        },
        buttonType: {
            type: String
        },
        firstButtonTitle:{
            type: String,
            default: 'Select Quote'
        }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    this.$router.go(0);
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getSecondButtonTitle(){
            if(this.secondAction === null){
                return "";
            }
            return "Select quote with CMS compete.";
        },
        secondPerform() {
            this.submittingSecond = true;
            this.secondAction.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingSecond = false;
                    return;
                }
                this.displayModal = false;
                this.submittingSecond = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    this.$router.go(0);
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingSecond = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        supplierChanged(value){
            if(parseInt(this.formData['cms_supplier_id']) === parseInt(value) ||
                (this.formData['immediate_payment'] !== 'no' && this.formData['immediate_payment'] !== null)){
                this.showSelectCompetingQuoteButton = false;
            }else{
                this.showSelectCompetingQuoteButton = true;
            }
        },
        immediatePaymentChanged(value){
            console.log(value);
            if(parseInt(this.formData['cms_supplier_id']) === this.formData['supplier_id'] ||
                (value !== 'no' && value !== null)){
                this.showSelectCompetingQuoteButton = false;
            }else{
                this.showSelectCompetingQuoteButton = true;
            }
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        },
        isActionRequired(fieldId){
            if (!this.action) {
                return false;
            }
            let field = this.action.fields.filter(field => field.name === fieldId).first();
            if(field){
                if(field.validation != null) {
                    return field.validation.includes('required')
                }
            }
            return false;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
