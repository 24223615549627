<template>
    <div data-cy="split-order">
        <custom-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">
            {{action.title}}
        </custom-button>
        <portal to="destination">
            <large-model data-cy="split-order-modal" v-if="displayModal" :submitting="submittingModal" :title="action.title"
                         :confirmationString='`Are you sure you want to \"${action.title}\"`'
                         @removeFromDisplay="displayModal = false" @perform="perform()">
                <h3 class="text-lg leading-6 font-medium">
                    Split order
                </h3>

                <div class="my-4">
                    <component v-if="getField('quick_reference')" :is="getFieldComponent(getField('quick_reference').type)" :field="getField('quick_reference')" v-model="formData[getField('quick_reference').name]">
                    </component>
                    <div v-show="errors.has(getField('quick_reference').name)" class="text-red-600">{{ errors.first(getField('quick_reference').name) }}</div>
                </div>
                <div class="my-4">
                    <p class="text-sm leading-5">
                        Select items to move to new order.
                    </p>
                    <div v-show="errors.has('items')"
                         class="text-sm leading-5 text-red-500">{{ errors.first('items') }}
                    </div>
                </div>

                <div class="flex flex-col">

                    <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border border-gray-200">
                            <table class="min-w-full goods-in-order-items">
                                <thead>
                                <tr>
                                    <th v-for="header in ['Description', 'Qty Ordered', 'Move to new order?']"
                                        class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        {{header}}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="orderItemEntity, key in orderItemEntities">
                                    <template v-if="!onlyIncludeProducts || (onlyIncludeProducts && orderItemEntity.entity.properties.get('product_id') !== null)">
                                        <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 font-medium">
                                            {{orderItemEntity.entity.properties.get('description')}}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm leading-5">
                                            {{orderItemEntity.entity.properties.get('quantity')}}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm leading-5">
                                            <component
                                                :is="getFieldComponent(getItemFieldByOrderItemEntity(orderItemEntity).type)"
                                                :field="getItemFieldByOrderItemEntity(orderItemEntity)"
                                                v-model="formData[getItemFieldByOrderItemEntity(orderItemEntity).name]">
                                            </component>
                                            <div v-show="errors.has(getItemFieldByOrderItemEntity(orderItemEntity).name)"
                                                 class="text-red-600">{{
                                                errors.first(getItemFieldByOrderItemEntity(orderItemEntity).name) }}
                                            </div>
                                        </td>
                                    </template>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </large-model>
        </portal>
    </div>
</template>

<script>
    import LargeModel from "@/v3/models/LargeModel.vue";
    import CustomButton from "@/v3/Buttons/CustomButton.vue";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false
            }
        },
        components: {
            CustomButton,
            LargeModel
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            response: {
                type: Object
            },
            additionalButtonClasses:{
                type: String
            },
            buttonType:{
                type: String
            },
            onlyIncludeProducts:{
                type: Boolean,
                default: false
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            orderItemEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.entities.filter(entity => entity.rels.contains('order_item'));
            },
        },
        methods: {
            getItemFieldByOrderItemEntity(orderItemEntity) {
                return this.action.fields.get('items[' + orderItemEntity.entity.properties.get('id') + ']');
            },
            getField(key) {
                return this.action.fields.get(key);
            },
            getFieldComponent(type) {
                return type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.$router.push(`/orders/${res.body.properties.get('id')}`);

                }).catch(error => {
                    this.submittingModal = false;
                    if (error.response.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                    // this.displayModal = false;
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },
    }
</script>

<style lang="scss">
    .goods-in-order-items label {
        display: none;
    }
</style>
