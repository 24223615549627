<template>
  <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2 col-span-2 overflow-hidden dark:text-white"
       v-if="details || loadingDetails">
    <div v-if="details" class="text-center sticky top-0 h-full grid grid-cols-3 w-ful gap-2 overflow-hidden">
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full overflow-y-auto">
        <div class="flex flex-col h-full">
          <div class="m-2">
            <div class="text-center">
              <div class="flex justify-between" v-if="hasTable">
                <a @click="$emit('hideTable')">
                  <svg v-if="!hideTable" class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6l6 6z"/>
                  </svg>
                  <svg v-else class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/>
                  </svg>

                </a>
                <a @click="openItemInNewTab">
                  <svg class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2zM14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3z"/>
                  </svg>
                </a>
              </div>
              <p class="text-xl mt-6">{{details.properties.get('purchase_order_number')}}</p>
              <p class="text-v3-800 opacity-60" v-if="details.properties.get('description')">{{details.properties.get('description')}}</p>
                <p class="flex justify-center my-2">
                    <status-badge :status="details.properties.get('status')"></status-badge>
                </p>
                <div class="text-left grid grid-cols-3 gap-x-2">
                    <div v-for="(action, index) in remainingActions">
                        <template v-if="!action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <email-preview-form additionalButtonClasses="my-2 w-full" v-if="action[0] === 'send-quotation-request'"
                                                :action="action[1]"
                                                :title="getTitle(action[0], action.title)"
                                                :actionKey="action[0]"
                                                :onSuccessPath="$router.currentRoute.fullPath"
                                                :buttonType="getColourType(action[0], index)"></email-preview-form>
                            <returns-form additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'create-return-request'"
                                          :response="details" :action="action[1]"
                                          :actionKey="action[0]"
                                          :onSuccessPath="$router.currentRoute.fullPath"
                                          :buttonType="getColourType(action[0], index)"
                                          :title="getTitle(action[0], action.title)">
                            </returns-form>
                            <update-scheduled-file-form v-else-if="action[0] === 'update-scheduled-file'"
                                                        :action="action[1]"
                                                        :actionKey="action[0]"
                                                        :onSuccessPath="$router.currentRoute.fullPath"
                                                        :secondAction="action[1]"
                                                        additionalButtonClasses="my-2 w-full"
                                                        :buttonType="getColourType(action[0], index)"
                            ></update-scheduled-file-form>
                            <ready-for-quote-form additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'mark-as-ready-for-quote'" :action="action[1]" :actionKey="action[0]" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)"></ready-for-quote-form>
                            <review-quotes-form additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'reject-competing-quote'" :response="response"
                                                :firstAction="action[1]" :secondAction="remainingActions.filter(action => action.fields.isEmpty() && action.name ==='approve-competing-quote').first()"
                                                :onSuccessPath="$router.currentRoute.fullPath"
                            ></review-quotes-form>
                            <reconcile-order-form v-else-if="action[0] === 'create-invoice'" :title="getTitle(action[0], action.title)" :response="details" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)" @success="formSuccess"></reconcile-order-form>

                            <form-action v-else :title="getTitle(action[0], action.title)" :response="details" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)" @success="formSuccess"></form-action>
                        </template>
                        <template v-else-if="action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <confirmation-action :title="getTitle(action[0], action.title)" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="action.name === 'delete-order'" :properties="details.properties" :buttonType="getColourType(action[0], index)" :on-success-path="action.name === 'delete-order' ? '/orders': $router.currentRoute.fullPath" @success="formSuccess"

                            ></confirmation-action>
                        </template>
                    </div>
                </div>
              </div>

              <header-link class="my-4" @click="tabOpen = 'details'" :isActive="tabOpen === 'details'" activeClass="bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20">
                <template v-slot:title>
                  <div class="text-left">
                    Details
                  </div>
                </template>
              </header-link>
              <header-link v-if="details.properties.get('show_invoices')" class="my-4" @click="tabOpen = 'invoices'" :isActive="tabOpen === 'invoices'" activeClass="bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20">
                <template v-slot:title>
                  <div class="text-left">
                    Invoices
                  </div>
                </template>
              </header-link>
                <header-link v-if="details.properties.get('rental_fee')" class="my-4" @click="tabOpen = 'rental-item-fees'" :isActive="tabOpen === 'rental-item-fees'" activeClass="bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20">
                    <template v-slot:title>
                        <div Class="text-left">
                            Rental Item Fees
                        </div>
                    </template>
                </header-link>
          </div>

        </div>
      </div>
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full col-span-2 overflow-y-auto text-left">
        <div class="p-2 h-full w-full">
          <div v-if="tabOpen === 'details'">
            <rental-item-details-pagination :entity="details" :collapsable="false" class="my-2"></rental-item-details-pagination>
          </div>
            <div v-if="tabOpen === 'invoices' && details.properties.get('show_invoices')">
                <order-invoices-pagination :order="details" @success="formSuccess">
                    <template  v-slot:additional-buttons>
                    <reconcile-order-form v-if="createInvoiceAction"
                                          :action="createInvoiceAction"
                                          :actionKey="createInvoiceAction.name"
                                          :onSuccessPath="$router.currentRoute.fullPath"
                                          additionalButtonClasses="my-2 w-full"
                                          :buttonType="getColourType(createInvoiceAction.name, 0)"
                                          title="Add Invoice"
                    >
                    </reconcile-order-form>
                    </template>
                </order-invoices-pagination>

            </div>
            <div v-if="tabOpen === 'rental-item-fees' && details.properties.get('rental_fee')">
                <rental-item-fee-details-pagination :entity="details" :collapsable="false" class="my-2"></rental-item-fee-details-pagination>
            </div>

        </div>
      </div>
    </div>
    <loading v-else class="sticky top-0"></loading>
  </div>
</template>
<script>
import BlankHeader from "@/v3/components/headers/BlankHeader.vue"
import HeaderLink from "@/v3/components/headers/HeaderLink.vue"
import Loading from '@/components/Loading'
import OrderActivityFeed from "@/v3/components/OrderActivityFeed.vue"
import OrderAttachmentsPagination from "@/v3/components/pagination/OrderAttachmentsPagination.vue"
import OrderBasicDetailsPagination from "@/v3/components/pagination/OrderBasicDetailsPagination.vue"
import OrderContractInfoPagination from "@/v3/components/pagination/OrderContractInfoPagination.vue"
import OrderItemsPagination from "@/v3/components/pagination/OrderItemsPagination.vue"
import OrderSchedulePagination from "@/v3/components/pagination/OrderSchedulePagination.vue"
import StatusBadge from "@/components/StatusBadge.vue"
import {mapGetters} from "vuex";
import fileDownload from "js-file-download";
import DownloadPdfInline from "@/v3/components/DownloadPdfInline.vue";
import OrderInvoicesPagination from "@/v3/components/pagination/OrderInvoicesPagination.vue";
import OrderCreditNotesPagination from "@/v3/components/pagination/OrderCreditNotesPagination.vue";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import EmailPreviewForm from "@/v3/components/forms/EmailPreviewForm.vue";
import OrderQuotationsPagination from "@/v3/components/pagination/OrderQuotationPagination.vue";
import SelectQuoteForm from "@/v3/components/forms/SelectQuoteForm.vue";
import NewCreditNoteForm from "@/v3/components/forms/NewCreditNoteForm.vue";
import ReconcileOrderForm from "@/v3/components/forms/ReconcileOrderForm.vue";
import GoodsInNoteForm from "@/v3/components/forms/GoodsInNoteForm.vue";
import ReturnsForm from "@/v3/components/forms/ReturnsForm.vue";
import OrderAttachments from "@/v3/components/pagination/OrderAttachments.vue";
import OrderNotes from "@/v3/components/pagination/OrderNotes.vue";
import SplitOrderForm from "@/v3/components/forms/SplitOrderForm.vue";
import UpdateScheduledFileForm from "@/v3/components/forms/UpdateScheduledFileForm.vue";
import ReadyForQuoteForm from "@/v2/components/forms/ReadyForQuoteForm.vue";
import ReviewQuotesForm from "@/v2/components/forms/ReviewQuotesForm.vue";
import RentalItemDetailsPagination from "@/v3/components/pagination/RentalItemDetailsPagination.vue";
import RentalItemFeeDetailsPagination from "@/v3/components/pagination/RentalItemFeeDetailsPagination.vue";

export default {
  name: 'SingleRentalItemDetails',
  components: {
      RentalItemFeeDetailsPagination,
      RentalItemDetailsPagination,
      ReviewQuotesForm, ReadyForQuoteForm,
      UpdateScheduledFileForm, SplitOrderForm,
      OrderNotes,
      OrderAttachments,
      ReturnsForm,
      GoodsInNoteForm,
      ReconcileOrderForm, NewCreditNoteForm,
      SelectQuoteForm,
      OrderQuotationsPagination,
      EmailPreviewForm,
       FormAction, ConfirmationAction,
      OrderCreditNotesPagination,
      OrderInvoicesPagination,
      DownloadPdfInline,
    BlankHeader,
    HeaderLink,
    Loading,
    OrderActivityFeed,
    OrderAttachmentsPagination,
    OrderBasicDetailsPagination,
    OrderContractInfoPagination,
    OrderItemsPagination,
    OrderSchedulePagination,
    StatusBadge
  },
    data(){
      return {
          editing: false,
          editingOrderItems: false,
          tabOpen: 'details'
      }
    },
    props:{
      details: {
          type: Object,
          default: null
      },
        hideTable: {
          type: Boolean,
            default: false
        },
        loadingDetails:{
          type: Boolean,
            default: false
        },
        hasTable:{
          type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        initials() {
            if (this.orderUser.entity && this.orderUser.entity.properties.get('name')) {
                return this.orderUser.entity.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        orderUser(orderEntity) {
            let orderUser = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("user");
                })
                .first();

            if (!orderUser) {
                return null;
            }

            return orderUser;
        },
        orderSite(orderEntity) {
            let orderSite = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("site");
                })
                .first();

            if (!orderSite) {
                return null;
            }

            return orderSite;
        },
        orderProject(orderEntity) {
            let orderProject = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("project");
                })
                .first();

            if (!orderProject) {
                return null;
            }

            return orderProject;
        },
        selectedQuote(entity) {
            let selectedQuote = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("quote");
                })
                .first();

            if (!selectedQuote) {
                return null;
            }

            return selectedQuote;
        },
        cmsQuote(orderEntity) {
            let selectedQuote = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("cms_quote");
                })
                .first();

            if (!selectedQuote) {
                return null;
            }

            return selectedQuote;
        },
        updateAction() {
            if (!this.details) {
                return null;
            }
            return this.details.actions.filter(action => action.name === 'update').first()
        },
        updateActionData() {
            if (!this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        remainingActions() {
            return this.details.actions
                .filter(action =>
                    action.name !== 'update' &&
                    action.name !== 'update-invoice' &&
                    action.name !== 'add-new-item' &&
                    action.name !== 'search-products' &&
                    action.name !== 'add-product-to-order' &&
                    action.name !== 'filter-products' &&
                    action.name !== 'get-pre-priced-values' &&
                    action.name !== 'add-comment' &&
                    action.name !== 'flag-order' &&
                    action.name !== 'select-quote-for-approval' &&
                    action.name !== 'reconcile-order' &&
                    action.name !== 'create-invoice-in' &&
                    action.name !== 'add-delivery-confirmations' &&
                    action.name !== 'add-credit-note' &&
                    action.name !== 'update-quote' &&
                    action.name !== 'select-quote-and-ask-cms-to-beat' &&
                    action.name !== 'approve-quotes' &&
                    action.name !== 'reject-quotes' &&
                    action.name !== 'request-update' &&
                    action.name !== 'add-goods-in-note' &&
                    action.name !== 'mark-as-fully-delivered' &&
                    action.name !== 'split-order'
                );
        },
        updateQuoteAction(){
            return this.details.actions.filter(action=> action.name === 'update-quote').first()
        },
        createInvoiceAction(){
            return this.details.actions.filter(action=> action.name === 'reconcile-order' || action.name === 'create-invoice-in').first()
        },
        createCreditNoteAction(){
            return this.details.actions.filter(action=> action.name === 'add-credit-note').first()
        }
    },
    methods:{
        openItemInNewTab(){
            let selfLink = this.details.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            window.open(parts[1], '_blank');
        },
        editDetails(){
            this.editing = true;
        },
        saveDetails() {
            if(!this.submittingModal) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submittingModal = true;
                this.updateAction.perform(this.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.editing = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.details = res.body;
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        async saveItems(){
            if (!this.orderItemsSubmitting) {
                this.orderItemsSubmitting = true;
                this.$refs.orderItems.updateItems().then(res => {
                    this.editingOrderItems = false;
                    this.orderItemsSubmitting = false;
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);

                    this.$store.dispatch(
                        "setMessage",
                        'The order was saved successfully'
                    );
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.orderItemsSubmitting = false;
                })
            }
        },
        removeItem(){
            let entity = {'entity': this.details};
            this.$emit('setLoading');
            this.$emit('getDetails', entity);
        },
        getTitle(name, title){
            if(name === 'mark-as-ready-for-quote'){
                return 'Get Quote';
            }
            if(name === 'mark-as-take-off'){
                return 'Get Take Off';
            }
            if(name === 'delete-order'){
                return 'Delete';
            }
            if(name === 'send-quotation-request'){
                return 'Quote Req.'
            }
            if(name === 'mark-as-quotes-received'){
                return 'Quotes Received';
            }
            if(name === 'create-return-request'){
                return 'Return';
            }
            if(name === 'approve-quotes'){
                return 'Approve';
            }
            if(name === 'reject-quotes'){
                return 'Reject';
            }
            return title;
        },
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject') || action.includes('delete')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        formSuccess(res){
            let entity = {'entity': this.details};
            this.$emit('getDetails', entity);
        }
    }
}
</script>
