<template>
    <pagination title="Invoices" :response="response" :entities="invoices" header-colour-class="bg-transparent">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Reference
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Invoice PDF</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Net</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">VAT</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Gross</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Uploaded</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Actions</th>
        </template>
        <template v-slot:items>
            <tr v-for="invoice in invoices">
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <div>{{ invoice.entity.properties.get('reference') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <download-pdf-icon-with-link :url="invoice.entity.properties.get('file')"></download-pdf-icon-with-link>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{ invoice.entity.properties.get('net_value') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{ invoice.entity.properties.get('vat_value') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{ invoice.entity.properties.get('gross_value') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <template v-if="invoice.entity.properties.get('isUploaded')">
                        <svg class="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    </template>
                    <template v-else>
                        <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </template>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <reconcile-order-form class="mb-2" v-if="invoice.entity.actions.get('update-invoice')"
                                          :action="invoice.entity.actions.get('update-invoice')"
                                          actionKey="update-invoice"
                                          :onSuccessPath="$router.currentRoute.fullPath"
                                            @success="$emit('success')"
                                            :redirect-on-success="false">
                    </reconcile-order-form>
                    <confirmation-action class="mb-2" v-if="invoice.entity.actions.get('mark-invoice-as-uploaded-to-sage')"
                                         :action="invoice.entity.actions.get('mark-invoice-as-uploaded-to-sage')"
                                         actionKey="mark-invoice-as-uploaded-to-sage"
                                         :onSuccessPath="$router.currentRoute.fullPath"
                                          @success="$emit('success')"
                                         :redirect-on-success="false"
                                        title="Upload">
                    </confirmation-action>
                    <reassign-invoice-form class="mb-2" v-if="invoice.entity.actions.get('reassign-invoice')"
                                           :action="invoice.entity.actions.get('reassign-invoice')"
                                           actionKey="reassign-invoice"
                                           title="Reassign"
                                           :onSuccessPath="$router.currentRoute.fullPath"
                                           :isUploaded="invoice.entity.properties.get('isUploaded')"
                                            @success="$emit('success')"
                                            :redirect-on-success="false">
                    </reassign-invoice-form>
                    <delete-invoice-in-confirmation v-if="invoice.entity.actions.get('delete-invoice')"
                                                    :action="invoice.entity.actions.get('delete-invoice')"
                                                    actionKey="delete-invoice"
                                                    :onSuccessPath="$router.currentRoute.fullPath"
                                                    @success="$emit('success')"
                                                    :redirect-on-success="false"
                                                    :isButton="false">
                    </delete-invoice-in-confirmation>
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import dayjs from "dayjs";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
import ReassignInvoiceForm from "@/v3/components/forms/ReassignInvoiceForm.vue";
import ReconcileOrderForm from "@/v3/components/forms/ReconcileOrderForm.vue";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import DeleteInvoiceInConfirmation from "@/v3/components/forms/DeleteInvoiceInConfirmation.vue";
export default {
  name: 'OrderInvoicesPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false
      }
    },
    components: {
        DeleteInvoiceInConfirmation,
        ConfirmationAction,
        ReconcileOrderForm,
        ReassignInvoiceForm,
        DownloadPdfIconWithLink, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
        invoices() {
            if (!this.order) {
                return null;
            }
            return this.order.entities.filter(entity => entity.rels.contains('invoice'));
        },
    },
  props: {
    order: {},
      response: {
        type: Object,
          default: null
      }
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        showCreatedDaysAgo(orderEntity){
            let createdDate = dayjs(orderEntity.entity.properties.get('created_at'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'),'seconds').humanize(true)
        },
        showUpdatedDaysAgo(orderEntity){
            let createdDate = dayjs(orderEntity.entity.properties.get('updated_at_unformatted'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'),'seconds').humanize(true)
        },
        altViewClicked(){
            this.$emit('altViewClicked');
            this.viewUser = false;
        }
    }
}
</script>
