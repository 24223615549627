<template>
    <tr v-if="newItemAction">
        <td colspan="100">
            <new-order-item @removeItem="removeItem(key)" ref="newOrderItem" :response="response" v-for="value,key in qty"
                            :key="key" :keyProp="key" :defaultValues="value" :scope="getScope(key)" v-if="value !== null"
                            :action="response"></new-order-item>

            <tr class="text-center">
                <td colspan="100">
                    <div class="grid grid-cols-3 p-3">
                        <button @click="addNewItem()" data-cy="add-item"
                                class="col-start-2 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition duration-150 ease-in-out">
                            + New Order Item
                        </button>
                        <upload-items-from-csv @itemsImported="addImportedItems"></upload-items-from-csv>
                    </div>
                </td>
            </tr>
        </td>
    </tr>
</template>

<script>
    import NewOrderItem from "@/v3/components/NewOrderItem.vue";
    import UploadItemsFromCsv from "@/components/UploadItemsFromCsv.vue";

    export default {
        inject: ["$validator"],
        components: {
            NewOrderItem,
            UploadItemsFromCsv
        },
        props: {
            response: {
                type: Object
            },
        },
        data() {
            return {
                qty: []
            }
        },
        computed: {
            newItemAction() {
                return this.response.actions.filter(action => action.name === 'add-new-item').first();
            },
        },
        methods: {
            addImportedItems(obj) {
                obj.forEach(object => {
                    this.qty.push(object);
                });
                this.$emit('change');
                this.$store.dispatch(
                    "setMessage",
                    "CSV imported, please check the rows & save the order to persist the changes."
                );
            },
            removeItem(key) {
                this.$set(this.qty, key, null);
            },
            async addNewItems() {
                let addNewItemPromises = [];
                if (this.$refs.newOrderItem) {
                    for (const { index,row } of this.$refs.newOrderItem.map((row, index) => ({ index,row }))){
                        console.log(index);
                       addNewItemPromises.push(await row.create().catch(error => {
                            throw {
                                error: error,
                                scope: this.getScope(index)
                            };
                        }));
                    }
                }
                return addNewItemPromises;
            },
            getScope(index) {
                return 'new-order-item-row-' + index;
            },
            addNewItem() {
                this.qty.push({});
                this.$emit('change');
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
