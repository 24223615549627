<template>
    <pagination title="Details" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Quick Reference
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="quick_reference"
                        :action="editing ? action: null"
                        field-id="quick_reference"
                        :showLabel="false"
                        v-model="updateActionData['quick_reference']">
                    </property-or-field>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Specification
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="specifications"
                        :action="editing ? action: null"
                        fieldId="specifications"
                        :showLabel="false"
                        v-model="updateActionData['specifications']">
                    </property-or-field>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Delivery Method
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="delivery_method"
                        :action="editing ? action: null"
                        fieldId="delivery_method"
                        :showLabel="false"
                        v-model="updateActionData['delivery_method']">
                    </property-or-field>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Booking Required
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="booking_delivery"
                        :action="editing ? action: null"
                        fieldId="booking_delivery"
                        :showLabel="false"
                        v-model="updateActionData['booking_delivery']"
                    >
                    </property-or-field>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
    inject: ["$validator"],
  name: 'OrderBasicDetailsPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: true
      },
      action:{
        type: Object,
          default: null
      },
    editing:{
        type: Boolean,
        default: false
    },
      updateActionData:{
        type: Object
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
