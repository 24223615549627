<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>

                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>Procure</template>
            <template v-slot="slotProps">
            <fetch-siren-root ref="sirenRoot" :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <procurement-navigation :links="slotProps.links"></procurement-navigation>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden" :class="{'grid grid-cols-3': (details || loadingDetails) && !hideTable}">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                        <div class="p-2 overflow-y-auto h-full" :class="{'hidden': hideTable}">
                                            <rental-items-pagination ref="orderList" :response="response" :orders="getOrders(response)" @setDetails="showDetails" @setLoading="setLoading" @altViewClicked="altViewClicked">
                                                <template v-slot:additional-buttons>
                                                    <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter')">
                                                        <create-rental-form v-if="actionWithFields[0] === 'create-rental'"
                                                                            class="action-with-fields" :action="actionWithFields[1]"
                                                                            :actionKey="actionWithFields[0]"
                                                                            :redirect-on-success="false"
                                                                            @success="successForm">
                                                        </create-rental-form>
                                                        <form-action v-else class="action-with-fields" :action="actionWithFields[1]"
                                                                     :actionKey="actionWithFields[0]"
                                                                     :redirectOnSuccess="true"
                                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                                        ></form-action>
                                                    </div>
                                                </template>
                                            </rental-items-pagination>
                                        </div>
                                        <SingleRentalItemDetails ref="orderDetails" :details="details" :loadingDetails="loadingDetails" :hideTable="hideTable" @setLoading="setLoading" @getDetails="getDetails" @hideTable="toggleHideTable"/>
                                    </template>
                                </div>
                            </div>
                        </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import CreateRentalForm from "@/v3/components/forms/CreateRentalForm.vue";
import FetchSirenRoot from '@/components/FetchSirenRoot';
import FormAction from "@/v3/components/forms/FormAction.vue";
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import ProcurementNavigation from "@/v3/components/navigation/ProcurementNavigation.vue";
import RentalItemsPagination from "@/v3/components/pagination/RentalItemsPagination.vue";
import SingleRentalItemDetails from "@/v3/pages/SingleRentalItemDetails.vue";
import Siren from "super-siren";

export default {
    inject: ["$validator"],
    components: {
        CreateRentalForm, FormAction,
        SingleRentalItemDetails,
        RentalItemsPagination,
        ProcurementNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,

    },
    data() {
        return {
            slotProps: null,
            users: null,
            details: null,
            detailsOpen: false,
            loadingDetails: false,
            orders: null,
            hideTable: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        }

    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getOrders(response) {
            if (!response) {
                return null;
            }
            return response.body.entities.filter(entity => entity.rels.contains('rental-item'));
        },
        showDetails(details){
            this.$setLaravelValidationErrorsFromResponse('');
            this.details = details;
            this.loadingDetails = false;
        },
        setLoading(){
            this.$refs.orderDetails.editing = false;
            this.$refs.orderDetails.tabOpen = 'details';
            this.details = null;
            this.loadingDetails = true;
        },
        getDetails(entity){
            this.$refs.orderList.getDetails(entity);
        },
        toggleHideTable(){
            this.hideTable = !this.hideTable;
        },
        altViewClicked(){
            this.details = null;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=procure_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=procure_light"
        },
        getAllRentals(){
            this.$refs.sirenRoot.load();
        },
        successForm(res){
            this.getAllRentals();
            console.log(res.body);
            this.showDetails(res.body);
        }

    }
}
</script>

