<template>
    <tr class="order-item-row">
        <td class="px-3 py-4 whitespace-normal text-sm leading-5 text-gray-500 align-top sm:pl-6">
            <property-or-autocomplete-field ref="description" @input="change" @suggestionSelected="descriptionSuggestionChanged"
                                            :properties="orderItemEntity.entity.properties" :scope="scope"
                                            :searchAction="editing? searchAction: null" :action="editing? updateAction: null" fieldId="description"
                                            propertyKey="description"
                                            v-model="updateActionData['description']"
                                            :disabled="orderItemEntity.entity.properties && orderItemEntity.entity.properties.get('product_id') !== null"
                                :showLabel="false"></property-or-autocomplete-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 align-top">
            <property-or-autocomplete-field ref="code" @input="change" @suggestionSelected="codeSuggestionChanged"
                                            :properties="orderItemEntity.entity.properties" :scope="scope"
                                            :searchAction="editing? searchAction: null" :action="editing? updateAction: null" fieldId="code"
                                            propertyKey="code"
                                            v-model="updateActionData['code']"
                                            :disabled="orderItemEntity.entity.properties && orderItemEntity.entity.properties.get('product_id') !== null"
                                :showLabel="false"></property-or-autocomplete-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
            <property-or-field ref="next" @input="change" :properties="orderItemEntity.entity.properties"
                               title="Due Date"
                               :action="editing? updateAction: null" fieldId="due_date" propertyKey="due_date" :scope="scope"
                               v-model="updateActionData['due_date']"
                                :showLabel="false"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
            <property-or-field @input="change" :properties="orderItemEntity.entity.properties" title="Qty"
                               :action="editing? updateAction: null" fieldId="quantity" propertyKey="quantity" :scope="scope"
                               v-model="updateActionData['quantity']"
                                :showLabel="false"></property-or-field>
        </td>
        <td v-if="isDelivered" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
            <property-or-field @input="change" :properties="orderItemEntity.entity.properties" title="Delivered Qty"
                               :action="editing? updateAction: null" fieldId="delivered_quantity" propertyKey="delivered_quantity" :scope="scope"
                               v-model="updateActionData['delivered_quantity']"
                                :showLabel="false"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
            <property-or-field @input="change" :properties="orderItemEntity.entity.properties" title="Unit" :scope="scope"
                               :action="editing? updateAction: null" fieldId="unit" propertyKey="unit"
                               v-model="updateActionData['unit']"
                                :showLabel="false"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
            <span v-if="editing && removeAction" class="cursor-pointer font-normal underline text-gray-400" @click="removeItem()">remove</span>
        </td>
    </tr>
</template>

<script>
    import PropertyOrField from "@/components/PropertyOrField.vue";
    import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField.vue";
    import v3orderItemsUpdate from "@/mixins/v3orderItemsUpdate";

    export default {
        inject: ["$validator"],
        mixins: [v3orderItemsUpdate],
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            orderItemEntity: {
                type: Object,
            },
            scope: {
                type: String
            },
            response: {
                type: Object
            },
            editing:{
                type: Boolean,
                default: false
            }
        },
        computed: {
            updateAction() {
                return this.orderItemEntity.entity.actions.filter(action => action.name === 'update').first();
            },
            removeAction() {
                return this.orderItemEntity.entity.actions.filter(action => action.name === 'delete').first();
            },
            updateActionData() {
                if (!this.updateAction) {
                    return {};
                }
                if (!this.updateAction.fields) {
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            isDelivered(){
                return this.response.properties.get('is_delivered');
            },
        },
        methods: {
            async update() {
                if(this.updateAction) {
                    return await this.updateAction.perform(this.updateActionData);
                }
            },
            removeItem() {
                return this.removeAction.perform().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'Item removed successfully.'
                    );
                    this.$emit('removeItem');
                }).catch(error => {
                    alert('There was an error processing your request.' + error);
                });
            },
            change() {
                this.$emit('change');
            },
            descriptionSuggestionChanged(value)
            {
                let id = this.updateCode(value);
                if(id) {
                    this.updateActionData['product_id'] = id;
                }
            },
            codeSuggestionChanged(value)
            {
                let id = this.updateDescription(value);
                if(id) {
                    this.updateActionData['product_id'] = id;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
