<template>
    <pagination :allow-overflow="false" title="Schedule" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Schedule File
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="scheduled_file"
                        :action="editing ? action: null"
                        fieldId="scheduled_file"
                        :showLabel="false"
                        v-model="updateActionData['scheduled_file']">
                    </property-or-field>
                    <div v-show="errors.has('scheduled_file')" class="text-red-600">{{ errors.first('scheduled_file') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Expected By Date
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="expected_by_date"
                        :action="editing ? action: null"
                        fieldId="expected_by_date"
                        :showLabel="false"
                        v-model="updateActionData['expected_by_date']">
                    </property-or-field>
                    <div v-show="errors.has('expected_by_date')" class="text-red-600">{{ errors.first('expected_by_date') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
    inject: ["$validator"],
  name: 'OrderSchedulePagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: true
      },
      editing: {
        type: Boolean,
          default: false
      },
      action: {
        type: Object,
          default: null
      },
      updateActionData: {
        type: Object,
          default: null
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
