<template>
    <tr class="border-b border-v3-gray-900 dark:border-white border-opacity-10 dark:border-opacity-10">
        <template ref="newItem">
            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 align-top">
                <property-or-autocomplete-field ref="description" @suggestionSelected="descriptionSuggestionChanged" :scope="scope"
                                                :searchAction="searchAction" :action="newItemAction"
                                                fieldId="description" propertyKey="description"
                                                v-model="newItemActionData['description']"
                                    :showLabel="false"></property-or-autocomplete-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 align-top">
                <property-or-autocomplete-field ref="code" @suggestionSelected="codeSuggestionChanged" :scope="scope"
                                                :searchAction="searchAction" :action="newItemAction" fieldId="code"
                                                propertyKey="code"
                                                v-model="newItemActionData['code']"
                                    :showLabel="false"></property-or-autocomplete-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 align-top">
                <property-or-field ref="next" :scope="scope" :action="newItemAction" fieldId="due_date"
                                   propertyKey="due_date" v-model="newItemActionData['due_date']"
                                    :showLabel="false"></property-or-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 align-top">
                <property-or-field :scope="scope" :action="newItemAction" fieldId="quantity" propertyKey="quantity"
                                   v-model="newItemActionData['quantity']"
                                    :showLabel="false"></property-or-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 align-top">
                <property-or-field :scope="scope" :action="newItemAction" fieldId="unit" propertyKey="unit"
                                   v-model="newItemActionData['unit']"
                                    :showLabel="false"></property-or-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 align-top">
                <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem();">remove</span>
            </td>
        </template>
    </tr>
</template>

<script>
    import PropertyOrField from "@/components/PropertyOrField.vue";
    import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField.vue";
    import OrderItemsUpdate from "@/mixins/orderItemsUpdate";

    export default {
        inject: ["$validator"],
        mixins: [OrderItemsUpdate],
        data() {
            return {
                isSuccessfullyProcessed: false
            }
        },
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            response: {
                type: Object,
            },
            scope: {
                type: String
            },
            keyProp: {
                type: Number
            },
            defaultValues: {
                type: Object,
                default: {}
            }
        },
        computed: {
            newItemAction() {
                return this.response.actions.filter(action => action.name === 'add-new-item').first();
            },
            searchAction() {
                return this.response.actions.filter(action => action.name === 'search-products').first();
            },
            newItemActionData() {
                if (!this.newItemAction.fields) {
                    return {};
                }
                var payload = {};
                this.newItemAction.fields.forEach(function (field) {
                    if (this.defaultValues[field.name]) {
                        payload[field.name] = this.defaultValues[field.name]
                    } else if (field.value !== undefined) {
                        payload[field.name] = field.value;
                    }
                }, this);
                return payload;
            },
        },
        methods: {
            async create() {
                if (this.isSuccessfullyProcessed) {
                    return Promise.resolve();
                }

                return await this.newItemAction.perform(this.newItemActionData).then(res => {
                    this.isSuccessfullyProcessed = true;
                });
            },
            removeItem() {
                this.$emit('removeItem', this.keyProp);
            },
            descriptionSuggestionChanged(value)
            {
                let id = this.updateCode(value);
                if(id) {
                    this.newItemActionData['product_id'] = id;
                }
            },
            codeSuggestionChanged(value)
            {
                let id = this.updateDescription(value);
                if(id) {
                    this.newItemActionData['product_id'] = id;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
