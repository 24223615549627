<template>
    <pagination title="Quotations" :response="response" :entities="suppliersSentTo" :hasEntities="suppliersSentTo.count() > 0" header-colour-class="bg-transparent">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Supplier
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Value</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">PDF</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Actions</th>
        </template>
        <template v-slot:items>
            <tr v-for="supplier in suppliersSentTo">
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <div>
                        {{supplier.entity.properties.get('supplier_name')}}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{ supplier.entity.properties.get('total')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <download-pdf-icon-with-link v-if="supplier.entity.properties.get('pdf')" :url="supplier.entity.properties.get('pdf')"></download-pdf-icon-with-link>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <select-quote-form v-if="supplier.entity.actions.filter(action => action.name === 'select-quote').first()"
                       :action="supplier.entity.actions.filter(action => action.name === 'select-quote').first()"
                       :actionKey="'select-quote'"
                       :onSuccessPath="$router.currentRoute.fullPath"
                       additionalButtonClasses="my-1"
                       buttonType="primary"
                       :firstButtonTitle="supplier.entity.actions.filter(action => action.name === 'select-quote').first().title"
                       :redirectOnSuccess="false" @success="$emit('success')"
                    ></select-quote-form>
                    <confirmation-action v-if="supplier.entity.actions.filter(action => action.name === 'use-quote').first()"
                         :action="supplier.entity.actions.filter(action => action.name === 'use-quote').first()"
                         action-key="use-quote"
                         :onSuccessPath="$router.currentRoute.fullPath"
                         additionalButtonClasses="my-1"
                         buttonType="primary"
                         :redirectOnSuccess="false" @success="$emit('success')">
                    </confirmation-action>
                </td>
            </tr>

        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
import SelectQuoteForm from "@/v3/components/forms/SelectQuoteForm.vue";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
export default {
  name: 'OrderQuotationsPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false
      }
    },
    components: {
        FormAction,
        ConfirmationAction,
        SelectQuoteForm,
        DownloadPdfIconWithLink, FlagOrderAction, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.order.actions.filter(action => action.name === 'filter').first();
        },
        items() {
            if (!this.order) {
                return null;
            }
            return this.order.entities.filter(entity => entity.rels.contains('quote'));
        },
        updateAction(){
            return this.order.actions.filter(action=> action.name === 'update-quote').first()
        }
    },
  props: {
    order: {},
      response: {
        type: Object,
          default: null
      },
      suppliersSentTo:{
        type: Object,
          default: null
      }
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        showCreatedDaysAgo(orderEntity){
            let createdDate = dayjs(orderEntity.entity.properties.get('created_at'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'),'seconds').humanize(true)
        },
        showUpdatedDaysAgo(orderEntity){
            let createdDate = dayjs(orderEntity.entity.properties.get('updated_at_unformatted'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'),'seconds').humanize(true)
        },
        altViewClicked(){
            this.$emit('altViewClicked');
            this.viewUser = false;
        }
    }
}
</script>
