<template>
    <pagination title="Details & Attachments" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Supplier Notes
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="supplier_notes"
                        :action="editing ? action: null"
                        fieldId="additional_notes"
                        :showLabel="false"
                        v-model="updateActionData['additional_notes']">
                    </property-or-field>
                    <div v-show="errors.has('additional_notes')" class="text-red-600">{{ errors.first('additional_notes') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr v-if="entity.properties.get('procurement_notes') || (editing && action.fields.filter(field => field.name === 'procurement_notes').first())">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Procurement Notes
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="procurement_notes"
                        :action="editing ? action: null"
                        fieldId="procurement_notes"
                        :showLabel="false"
                        v-model="updateActionData['procurement_notes']">
                    </property-or-field>
                    <div v-show="errors.has('procurement_notes')" class="text-red-600">{{ errors.first('procurement_notes') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Attachment
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="attachment"
                        :action="editing ? action: null"
                        fieldId="attachment"
                        :showLabel="false"
                        v-model="updateActionData['attachment']">
                    </property-or-field>
                    <div v-show="errors.has('attachment')" class="text-red-600">{{ errors.first('attachment') }}</div>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
    inject: ["$validator"],
  name: 'OrderAttachmentsPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: true
      },
      editing: {
          type: Boolean,
          default: false
      },
      action: {
          type: Object,
          default: null
      },
      updateActionData: {
          type: Object,
          default: null
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
